import { Skeleton } from '@/@components/ui/skeleton'
import { STATE_BASICS } from '@/features/game-crash/utils/constant'
import { selectPlayerInfo } from '@/features/player/slice'
import { useSelector } from 'react-redux'

const HeaderExternal = () => {
  const playerInfo = useSelector(selectPlayerInfo)

  const renderContent = () => {
    if (playerInfo.state === STATE_BASICS.Pending) {
      return (
        <div className="flex items-center space-x-4">
          <div className="space-y-2">
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>
      )
    }
    if (playerInfo.state === STATE_BASICS.Fulfilled) {
      return (
        <div className="flex items-center space-x-4">
          <div className="space-y-2">
            <div className="text-white">
              {playerInfo.data?.cash}
              {playerInfo.data?.currency}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <header className="bg-header">
      <nav className="relative flex w-full flex-wrap items-center justify-between py-2 text-neutral-500 lg:py-4">
        <div className="flex w-full flex-wrap items-center justify-end px-3">
          <div className="flex gap-4">{renderContent()}</div>
        </div>
      </nav>
    </header>
  )
}

export default HeaderExternal
