import { GROW_CONSTANT } from '@constants/CrashConst'

export function calcGrowth(ms) {
  const r = GROW_CONSTANT
  return Math.floor(100 * Math.pow(Math.E, r * ms))
}

export function calcGrowthRaw(ms) {
  const r = GROW_CONSTANT
  return Math.pow(Math.E, r * ms)
}
