import { createGameEvent } from '@/features/game-crash/actions'
import { selectPlayerInfo } from '@/features/player/slice'
import { isArray } from 'ramda-adjunct'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { map, merge } from 'rxjs'
import {
  getCurrentBetHistoryFulfilled,
  resetCurrentBetHistory,
  selectCurrentBetHistory,
  updateFinalPlayerStatuses,
} from '../slice'
import { CRASH_EVENTS } from '../utils/constant'
import {
  calcGameRound,
  concatStaters,
  staterPlayerStatuses,
} from '../utils/helper'

export const useCurrentBetHistory = () => {
  const dispatch = useDispatch()
  const currentBetHistory = useSelector(selectCurrentBetHistory)
  const playerInfo = useSelector(selectPlayerInfo)

  const history = useMemo(() => {
    return concatStaters(currentBetHistory, playerInfo)
  }, [currentBetHistory, playerInfo])

  useEffect(() => {
    const selectPlayers = (data) => data?.players

    const join$ = createGameEvent(CRASH_EVENTS.JOIN).pipe(map(selectPlayers))
    const players$ = createGameEvent(CRASH_EVENTS.PLAYERS)
    const cashedOut$ = createGameEvent(CRASH_EVENTS.CASHED_OUT).pipe(
      map(selectPlayers)
    )
    const su = merge(join$, players$, cashedOut$).subscribe((players) => {
      if (isArray(players)) {
        const out = players.map((p) => {
          let status = staterPlayerStatuses.betting()
          if (p?.stoppedAt) {
            status = staterPlayerStatuses.cashedOut(calcGameRound(p?.stoppedAt))
          } else if (p?.status === 1) {
            status = staterPlayerStatuses.bang()
          }
          return { ...p, _status: status }
        })
        dispatch(getCurrentBetHistoryFulfilled(out))
      }
    })
    return () => {
      su.unsubscribe()
    }
  }, [dispatch])

  useEffect(
    function updatePlayerStatusesAtGameEnded() {
      const su = createGameEvent(CRASH_EVENTS.GAME_ENDED).subscribe(() => {
        dispatch(updateFinalPlayerStatuses())
      })
      return () => {
        su.unsubscribe()
      }
    },
    [dispatch]
  )

  useEffect(
    function resetTable() {
      const su = createGameEvent(CRASH_EVENTS.GAME_STARTING).subscribe(() => {
        dispatch(resetCurrentBetHistory())
      })
      return () => {
        su.unsubscribe()
      }
    },
    [dispatch]
  )

  return history
}
