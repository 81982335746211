import { createBrowserHistory } from 'history'

// Create a history object
const history = createBrowserHistory()

// Function to update the query string in the URL
export function updateQueryString(updatedParams) {
  // Get the current location object
  const location = history.location

  // Get the current URL parameters using URLSearchParams
  const urlSearchParams = new URLSearchParams(location.search)

  // Update the query parameters using the provided values
  Object.entries(updatedParams).forEach(([param, value]) => {
    if (value === '' || value === null || value === undefined) {
      // If the value is empty, remove the key from the URL
      urlSearchParams.delete(param)
    } else {
      urlSearchParams.set(param, value)
    }
  })

  // Get the updated query string
  const updatedQueryString = urlSearchParams.toString()

  // Create a new location object with the updated query string
  const newLocation = {
    ...location,
    search: updatedQueryString,
  }

  // Use history.push or history.replace to update the URL without page reload
  history.push(newLocation) // Use history.replace(newLocation) if you want to replace the current history entry
}

export function getQueryString(key) {
  // Get the current location object
  const location = history.location

  // Get the current URL parameters using URLSearchParams
  const urlSearchParams = new URLSearchParams(location.search)
  return urlSearchParams.get(key)
}
