import { ofType } from 'redux-observable'
import { of, switchMap } from 'rxjs'
import { STATE_BASICS } from '../game-crash/utils/constant'
import { updateCash, updateCashFulfilled, updateCashRejected } from './slice'

/**
 * @param {import('@/shared/types').Observable} action$
 */
const updateCashEpic = (action$, state) =>
  action$.pipe(
    ofType(updateCash.type),
    switchMap(({ payload }) => {
      // TODO: validate cash

      if (state.value.player.info.state !== STATE_BASICS.Fulfilled) {
        return of(updateCashRejected())
      }
      return of(updateCashFulfilled(payload))
    })
  )

const playerEpics = [updateCashEpic]

export default playerEpics
