import { countDown, createGameEvent } from '@/features/game-crash/actions'
import { addNotification } from '@/features/notifications/slice'
import { socket } from '@/features/socket'
import { calcGrowthRaw } from '@/utilities/utils-crash'
import { inIframe } from '@/utilities/utils-miscellaneous'
import { createNotification } from '@/utilities/utils-notification'
import Container from '@components/Container'
import { GAME_STATUS } from '@constants/GameConst'
import complement from 'ramda/src/complement'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  first,
  of,
  repeat,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { selectConfig } from '../app/slice'
import AuthPanel from '../auth/components/AuthPanel'
import { selectIsLoggedIn } from '../auth/slice'
import BetForm from './components/BetForm'
import Latest from './components/Latest'
import PlayerTable from './components/PlayerTable'
import StatusCountDown from './components/StatusCountDown'
import StatusTick from './components/StatusTick'
import GameLogic from './game-logic'
import { CRASH_EVENTS, CRASH_GAME_ERROR } from './utils/constant'

const GameCrash = () => {
  const dispatch = useDispatch()
  const refCanvas = useRef(null)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  /**
   * A reference to the `GameLogic` instance used in the `Crash` component.
   *
   * @type {React.MutableRefObject<GameLogic>}
   */
  const refGame = useRef(null)

  const [gameStatus, setGameStatus] = useState(GAME_STATUS.NIL)
  const [tick, setTick] = useState()
  const [count, setCount] = useState()
  const [crash, setCrash] = useState()
  const config = useSelector(selectConfig)
  const isIframe = inIframe() || config.iframe

  const refGameStatus = useRef(new BehaviorSubject(GAME_STATUS.NIL))

  const gameStatus$ = useMemo(() => {
    return refGameStatus.current.asObservable()
  }, [])

  useEffect(() => {
    refGame.current = new GameLogic(refCanvas.current, socket)
    globalThis.gameLogic = refGame.current
    return () => {
      refGame.current.destroy()
    }
  }, [])

  useEffect(() => {
    const suGameStarting = createGameEvent(CRASH_EVENTS.GAME_STARTING)
      .pipe(
        tap((data) => {
          const game_id = data?.game_id
          if (!game_id) {
            throw new Error('game_id is required')
          }
          setCrash(undefined)
          refGame.current.start()
          setGameStatus(GAME_STATUS.START)
          refGameStatus.current.next(GAME_STATUS.START)
          setTick(undefined)
        }),
        switchMap(({ time_till_start }) => {
          const subCountDown = countDown(time_till_start).subscribe((count) => {
            setCount(count)
          })
          const gameTick$ = createGameEvent(CRASH_EVENTS.GAME_TICK).pipe(
            first()
          )

          return new Observable((observer) => {
            gameTick$.subscribe({
              next(value) {
                observer.next(value)
              },
              error(err) {
                subCountDown.unsubscribe()
                observer.error(err)
              },
              complete() {
                subCountDown.unsubscribe()
                setCount(undefined)

                // set timeline
                setGameStatus(GAME_STATUS.TICK)
                refGameStatus.current.next(GAME_STATUS.TICK)
              },
            })
          })
        }),
        catchError((err) => {
          // TODO: handle error
          console.log('inspect.err', err)
          return of(err)
        })
      )
      .subscribe()
    return () => {
      suGameStarting.unsubscribe()
    }
  }, [])

  useEffect(() => {
    const isNotZeroAtMidway = complement(
      (v) => v === 0 && refGame.current.isMidway()
    )

    const suGameTick = createGameEvent(CRASH_EVENTS.GAME_TICK)
      .pipe(
        takeUntil(createGameEvent(CRASH_EVENTS.GAME_ENDED)),
        startWith(0),
        filter(isNotZeroAtMidway),
        repeat({ delay: () => createGameEvent(CRASH_EVENTS.GAME_STARTING) })
      )
      .subscribe((data) => {
        // set timeline
        if (refGame.current.isMidway()) {
          setGameStatus(GAME_STATUS.TICK_MIDWAY)
          refGameStatus.current.next(GAME_STATUS.TICK_MIDWAY)
        }

        const y = calcGrowthRaw(data)
        setTick(y.toFixed(2))

        refGame.current.addData({ x: data, y })
      })
    return () => {
      suGameTick.unsubscribe()
    }
  }, [])

  useEffect(() => {
    const suGameEnd = createGameEvent(CRASH_EVENTS.GAME_ENDED).subscribe(
      ({ elapsed, game_crash }) => {
        const y = calcGrowthRaw(elapsed)
        setTick(y.toFixed(2))

        setGameStatus(GAME_STATUS.CRASH)
        refGameStatus.current.next(GAME_STATUS.CRASH)
        setCrash(`${(game_crash / 100).toFixed(2)}x`)
      }
    )
    return () => {
      suGameEnd.unsubscribe()
    }
  }, [dispatch])

  useEffect(() => {
    const suCashedOut = createGameEvent(CRASH_EVENTS.CASHED_OUT).subscribe(
      ({ username, stopped_at }) => {
        dispatch(
          addNotification(createNotification(`Cashed out at ${stopped_at}`))
        )
      }
    )

    return () => {
      suCashedOut.unsubscribe()
    }
  }, [dispatch])

  const handleBetFail = useCallback((error) => {
    if (error?.code === CRASH_GAME_ERROR.GameInProgress) {
      setGameStatus(GAME_STATUS.TICK_MIDWAY)
      refGameStatus.current.next(GAME_STATUS.TICK_MIDWAY)
    }
  }, [])

  const handleCashOut = () => {
    setGameStatus(GAME_STATUS.TICK_MIDWAY)
    refGameStatus.current.next(GAME_STATUS.TICK_MIDWAY)
  }

  return (
    <div className="flex flex-col gap-4 xl:flex-row">
      <Container full className="flex flex-1 flex-col gap-4">
        <div className="relative p-4 md:rounded-md md:border-2 md:bg-card">
          <StatusCountDown count={count} />
          <StatusTick gameStatus={gameStatus} tick={tick} />
          <canvas ref={refCanvas} width="400" height="250" />
        </div>
        <Container className="xl:px-0">
          {isLoggedIn ? (
            <BetForm
              gameStatus={gameStatus}
              gameStatus$={gameStatus$}
              onBetFail={handleBetFail}
              onCashOut={handleCashOut}
            />
          ) : isIframe ? null : (
            <AuthPanel />
          )}
        </Container>
        {config.dev ? (
          <Container>
            <pre>
              {JSON.stringify(
                {
                  gameStatus,
                  count,
                  tick: tick ? `${tick}x` : undefined,
                  crash,
                },
                null,
                2
              )}
            </pre>
          </Container>
        ) : null}
        <Container className="xl:px-0">
          <Latest />
        </Container>
      </Container>
      <PlayerTable />
    </div>
  )
}

export default GameCrash
