import { createSlice } from '@reduxjs/toolkit'
import { stater, staterPager } from './utils/helper'

/**
 * The initial state for the `crash` slice.
 *
 * @typedef {Object} StateCrash
 * @property {import('@/features/game-crash/utils/helper').StateObject} currentBetHistory - The current bet history.
 * @property {import('@/features/game-crash/utils/helper').StateObject} betHistory - The bet history.
 */

/** @type {StateCrash} */
const initialState = {
  currentBetHistory: stater.nil(),
  betHistory: staterPager.nil(),
  playerHistory: staterPager.nil(),
}

export const crashSlice = createSlice({
  name: 'crash',
  initialState,
  reducers: {
    getCurrentBetHistory: (state) => {
      state.currentBetHistory = stater.pending()
    },
    getCurrentBetHistoryFulfilled: (state, { payload }) => {
      state.currentBetHistory = stater.fulfilled(payload)
    },
    getCurrentBetHistoryRejected: (state) => {
      state.currentBetHistory = stater.rejected()
    },
    resetCurrentBetHistory: (state) => {
      state.currentBetHistory = stater.nil()
    },
    updateFinalPlayerStatuses: () => {},
    getBetHistory: (state, payload) => {
      const meta = payload?.meta ?? state.betHistory.meta
      state.betHistory = staterPager.pending(meta)
    },
    getBetHistoryFulfilled: (state, { payload: { data, meta } }) => {
      state.betHistory = staterPager.fulfilled(data, meta)
    },
    getBetHistoryRejected: (state) => {
      state.betHistory = staterPager.rejected(state.betHistory.meta)
    },

    getPlayerHistory: (state, payload) => {
      const meta = payload?.meta ?? state.playerHistory.meta
      state.playerHistory = staterPager.pending(meta)
    },
    getPlayerHistoryFulfilled: (state, { payload: { data, meta } }) => {
      state.playerHistory = staterPager.fulfilled(data, meta)
    },
    getPlayerHistoryRejected: (state) => {
      state.playerHistory = staterPager.rejected(state.playerHistory.meta)
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  getCurrentBetHistory,
  getCurrentBetHistoryFulfilled,
  getCurrentBetHistoryRejected,
  resetCurrentBetHistory,
  updateFinalPlayerStatuses,
  getBetHistory,
  getBetHistoryFulfilled,
  getBetHistoryRejected,
  getPlayerHistory,
  getPlayerHistoryFulfilled,
  getPlayerHistoryRejected,
} = crashSlice.actions

export default crashSlice.reducer

/**
 * Returns the player info from the Redux store.
 *
 * @function
 * @param {Object} state - The Redux store state.
 * @returns {import('@/features/game-crash/utils/helper').StateObject} The player info object, which represents the state of the player info with an optional data property.
 */
export const selectCurrentBetHistory = (state) => state.crash.currentBetHistory

/**
 * Returns the player info from the Redux store.
 *
 * @function
 * @param {Object} state - The Redux store state.
 * @returns {import('@/features/game-crash/utils/helper').StateObject} The player info object, which represents the state of the player info with an optional data property.
 */
export const selectBetHistory = (state) => state.crash.betHistory

/**
 * Returns the player info from the Redux store.
 *
 * @function
 * @param {Object} state - The Redux store state.
 * @returns {import('@/features/game-crash/utils/helper').StateObject} The player info object, which represents the state of the player info with an optional data property.
 */
export const selectPlayerHistory = (state) => state.crash.playerHistory
