import AppError from '@/base/classes/AppError'
import { updateOverlay } from '@/features/app/slice'
import { updateQueryString } from '@/features/auth/history'
import {
  getProfile,
  getProfileFulfilled,
  getProfileRejected,
  isLoggedIn,
  signIn,
  signInExternal,
  signInExternalFulfilled,
  signInFulfilled,
  signInRejected,
  signOut,
  signOutFulfilled,
  signUp,
  signUpFulfilled,
  signUpRejected,
} from '@/features/auth/slice'
import { addNotification } from '@/features/notifications/slice'
import { getPlayerInfoFulfilled } from '@/features/player/slice'
import { updateSocketAuth } from '@/features/socket'
import { getURL } from '@/utilities'
import { getToken, removeCookie, setCookie } from '@/utilities/cookie'
import { fulfill, pend, selectTransaction } from '@/utilities/utils-action'
import { getFutureDate } from '@/utilities/utils-date'
import { createNotification } from '@/utilities/utils-notification'
import { isValidString } from '@/utilities/utils-string'
import { ERROR_TYPE, OVERLAYS, QUERY_STRINGS } from '@constants/AppConst'
import { COOKIES } from '@constants/CookieConst'
import { ofType } from 'redux-observable'
import {
  EMPTY,
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
} from 'rxjs'
import { ajax } from 'rxjs/ajax'

export const setJwtAuth = (token) => {
  setCookie(COOKIES.JWT_AUTH, token, getFutureDate(8, 'h'))
}

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const signInEpic = (action$) =>
  action$.pipe(
    ofType(signIn.type),
    mergeMap((action) =>
      ajax.post(getURL('/sign-in'), action.payload).pipe(
        switchMap((ajaxResponse) => {
          if (!isValidString(ajaxResponse.response.token)) {
            throw new AppError({
              message: 'invalid token',
              type: ERROR_TYPE.API_FRONTEND_INVALID,
            })
          }
          // Effect: Store response.token in a cookie
          setJwtAuth(ajaxResponse.response.token)

          // reconnect for update token
          updateSocketAuth()

          return of(pend(getProfile(), 'signIn'))
        }),
        catchError((err) => {
          return of(signInRejected(err))
        })
      )
    )
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const getProfileAtSignInEpic = (action$) =>
  action$.pipe(
    ofType(getProfileFulfilled.type),
    filter((action) => {
      return selectTransaction(action).type === 'signIn'
    }),
    switchMap(() =>
      of(
        signInFulfilled(),
        updateOverlay(OVERLAYS.NIL),
        addNotification(createNotification('Login Successful'))
      )
    )
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const signOutEpic = (action$) =>
  action$.pipe(
    ofType(signOut.type),
    switchMap(() => {
      if (!isLoggedIn.current) return of(signOutFulfilled())

      isLoggedIn.current = false
      removeCookie(COOKIES.JWT_AUTH)
      updateSocketAuth()

      return of(signOutFulfilled())
    })
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const updateOverlayEpic = (action$) =>
  action$.pipe(
    ofType(updateOverlay.type),
    switchMap(({ payload }) => {
      if (payload === OVERLAYS.NIL) {
        updateQueryString({ [QUERY_STRINGS.OVERLAY]: OVERLAYS.NIL })
      }
      return EMPTY
    })
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const signUpEpic = (action$) =>
  action$.pipe(
    ofType(signUp.type),
    mergeMap((action) =>
      ajax.post(getURL('/sign-up'), action.payload).pipe(
        switchMap((ajaxResponse) => {
          // First, perform the sign-up
          const signUpActions = of(
            signUpFulfilled(ajaxResponse.response),
            updateOverlay(OVERLAYS.NIL)
          )

          // Then, initiate the sign-in by dispatching the signIn action with the sign-up data
          const signInAction = signIn({
            // Assuming your sign-up response contains the required sign-in data (e.g., email and password)
            email: action.payload.email,
            password: action.payload.password,
          })

          return concat(signUpActions, of(signInAction))
        }),
        catchError((err) => {
          return of(signUpRejected(err))
        })
      )
    )
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const getProfileEpic = (action$) =>
  action$.pipe(
    ofType(getProfile.type),
    mergeMap((action) =>
      ajax
        .get(getURL('/profile'), {
          Authorization: getToken(),
        })
        .pipe(
          map((ajaxResponse) => {
            return fulfill(
              getProfileFulfilled(ajaxResponse.response),
              action.meta
            )
          }),
          catchError((err) => {
            return of(getProfileRejected(err))
          })
        )
    )
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const signInExternalEpic = (action$) =>
  action$.pipe(
    ofType(signInExternal.type),
    mergeMap((action) =>
      ajax.post(getURL('/sign-in'), action.payload).pipe(
        switchMap((ajaxResponse) => {
          if (!ajaxResponse.response.validator?.status) {
            throw new AppError({
              message: ajaxResponse.response.validator?.message,
              type: ERROR_TYPE.API_BACKEND_INVALID,
            })
          }

          setJwtAuth(action.payload.game_token)

          // reconnect for update token
          updateSocketAuth()

          return of(
            signInExternalFulfilled(),
            getPlayerInfoFulfilled(ajaxResponse.response)
          )
        }),
        catchError((err) => {
          return of(signInRejected(err))
        })
      )
    )
  )

const authEpics = [
  signInEpic,
  getProfileAtSignInEpic,
  signOutEpic,
  updateOverlayEpic,
  signUpEpic,
  getProfileEpic,
  signInExternalEpic,
]

export default authEpics
