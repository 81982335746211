import { calcGrowthRaw } from '@/utilities/utils-crash'
import { OPTIONS } from '@components/ChartConst'
import { Chart } from 'chart.js'
import Decimal from 'decimal.js'
import { isInteger, isNotNilOrEmpty } from 'ramda-adjunct'
import { GAME_TICK } from './utils/constant'
import { mergeDeepLeftByPaths, setVal, viewLensPathNum } from './utils/helper'

class GameLogic {
  #isStarted = false

  constructor(canvasElement) {
    const ctx = canvasElement.getContext('2d')
    this.ctx = ctx
    const [chart, options] = GameLogic.createChart(ctx)
    this.chart = chart
    this.proxyOptions()
    this.options = options
  }

  static createChart(ctx) {
    const options = GameLogic.createOptions()
    return [
      new Chart(ctx, {
        type: 'line',
        options,
        data: GameLogic.initData(),
      }),
      options,
    ]
  }

  /**
   * @return {import('chart.js').ChartData}
   */
  static initData() {
    return {
      labels: [],
      datasets: [
        {
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          color: 'rgba(255, 255, 255,1)',
          fill: 'origin',
          pointRadius: 0,
          lineTension: 0.1,
          data: [],
        },
      ],
    }
  }

  /**
   * @return {import('chart.js').ChartOptions}
   */
  static createOptions(
    maxX = 10000,
    maxY = 2,
    stepSizeX = 1000,
    stepSizeY = 0.2
  ) {
    return {
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        line: {
          tension: 0.1,
        },
      },
      scales: {
        x: {
          type: 'linear',
          title: {
            display: false,
            text: 'value',
          },
          min: 0,
          max: maxX,
          ticks: {
            color: 'hsl(228, 63%, 82%)',
            stepSize: stepSizeX,
            callback: function (value, index, values) {
              value = Math.round(value / 1000)
              return value % 5 === 0 ? `${value}s` : ''
            },
            align: 'end',
            maxRotation: 0,
          },
          grid: {
            display: false,
            color: 'rgba(255,255,0,1)',
          },
        },
        y: {
          type: 'linear',
          title: {
            display: false,
            text: 'value',
          },
          min: 1,
          max: maxY,
          ticks: {
            color: 'hsl(228, 63%, 82%)',
            callback: function (value, index, values) {
              const x = new Decimal(value)
              if (x.mod(0.2).eq(0)) {
                return `${x.toFixed(1)}x`
              }
            },
            stepSize: stepSizeY,
          },
          grid: {
            display: false,
            color: 'rgba(255,0,255,1)',
          },
        },
      },
      plugins: {
        legend: { display: false },
      },
      animation: {
        x: {
          type: 'number',
          easing: 'linear',
          duration: 0,
          from: 5,
          delay: 0,
        },
        y: {
          type: 'number',
          easing: 'linear',
          duration: 0,
          from: 5,
          delay: 0,
        },
        loop: true,
      },
    }
  }

  proxyOptions() {
    this.proxy = new Proxy(this, {
      set(target, prop, value) {
        if (prop === 'options') {
          target.chart.options = value
        }
        return Reflect.set(...arguments)
      },
    })
  }

  addData({ x, y }) {
    try {
      const options = this.updateOptions(x, y)
      if (isNotNilOrEmpty(options)) {
        this.proxy.options = options
      }

      // handle chart consistent for multi players or reload page
      if (
        this.chart.data.labels <= OPTIONS.INIT_CHART_DATA_THRESHOLD &&
        x / OPTIONS.TICK_STEP > OPTIONS.MIN_SPACE_RANGE_TO_CURRENT
      ) {
        let start = 2
        while (start < x) {
          this.chart.data.labels.push(start)
          const pseudoY = calcGrowthRaw(start)
          this.chart.data.datasets[0].data.push(pseudoY)
          start += 150
        }
        this.#isStarted = true
      }

      // if (this.isMidway()) {
      //   this.#pushDataMidway(x, y)
      //   this.#isStarted = true
      // } else {
      //   this.#pushData(x, y)
      // }
      this.#pushData(x, y)
      this.chart.update()
    } catch (err) {
      /** REASON:
       * - server not return number for number value
       */
      console.log('inspect.err', err)
    }
  }

  #pushData(x, y) {
    this.chart.data.labels.push(x)
    this.chart.data.datasets[0].data.push(y)
  }

  #pushDataMidway(x, y) {
    let length = x / GAME_TICK
    if (isInteger(length)) {
      length = length - 1
    } else {
      length = Math.floor(length)
    }
    for (let i = 1, px = 0; i <= length; i++, px += GAME_TICK) {
      const py = calcGrowthRaw(px)
      this.chart.data.labels.push(py)
      this.chart.data.datasets[0].data.push(px)
    }
  }

  start() {
    this.#isStarted = true
    this.proxy.chart.data = GameLogic.initData()
    this.proxy.options = GameLogic.createOptions()
    this.chart.update()
  }

  updateOptions(x, y) {
    const conf = {}
    const otp = this.options
    const pathMaxX = ['scales', 'x', 'max']
    const pathMaxY = ['scales', 'y', 'max']
    const paths = []
    const maxX = viewLensPathNum(pathMaxX)
    const maxY = viewLensPathNum(pathMaxY)
    const setMaxX = setVal(pathMaxX, conf)
    const setMaxY = setVal(pathMaxY, conf)
    try {
      if (maxX(otp).sub(x).lte(0)) {
        setMaxX(x)
        paths.push(pathMaxX)
      }

      if (maxY(otp).sub(y).lte(0)) {
        setMaxY(y)
        paths.push(pathMaxY)
      }

      let newOpt = null
      if (isNotNilOrEmpty(conf)) {
        newOpt = mergeDeepLeftByPaths(paths, otp, conf)
      }
      return newOpt
    } catch (err) {
      console.log('inspect.err', err)
      return null
    }
  }

  isMidway() {
    return !this.#isStarted
  }

  destroy() {
    this.chart.notifyPlugins('destroy')
    this.chart.destroy()
  }
}

export default GameLogic
