import { selectOverlay } from '@/features/app/slice'
import FormSignIn from '@/features/auth/components/FormSignIn'
import FormSignUp from '@/features/auth/components/FormSignUp'
import { OVERLAYS } from '@/shared/constants/AppConst'
import { useSelector } from 'react-redux'

const ModalGlobal = () => {
  const overlay = useSelector(selectOverlay)

  return (
    <>
      {overlay === OVERLAYS.SIGN_IN ? <FormSignIn open /> : null}
      {overlay === OVERLAYS.SIGN_UP ? <FormSignUp open /> : null}
    </>
  )
}

export default ModalGlobal
