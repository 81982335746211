import { createSlice } from '@reduxjs/toolkit'
import { stater } from '../game-crash/utils/helper'

const initialState = {
  info: stater.nil(),
}
export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    getPlayerInfo: (state) => {
      state.info = stater.pending()
    },
    getPlayerInfoFulfilled: (state, action) => {
      state.info = stater.fulfilled(action.payload)
    },
    getPlayerInfoRejected: (state) => {
      state.info = stater.rejected()
    },
    updateCash: (state, action) => {},
    updateCashFulfilled: (state, action) => {
      state.info = stater.fulfilled({
        ...state.info.data,
        cash: action.payload,
      })
    },
    updateCashRejected: (state) => {},
  },
})

// Action creators are generated for each case reducer function
export const {
  getPlayerInfo,
  getPlayerInfoFulfilled,
  getPlayerInfoRejected,
  updateCash,
  updateCashFulfilled,
  updateCashRejected,
} = playerSlice.actions

export default playerSlice.reducer

/**
 * Returns the player info from the Redux store.
 *
 * @function
 * @param {Object} state - The Redux store state.
 * @returns {import('@/features/game-crash/utils/helper').StateObject} The player info object, which represents the state of the player info with an optional data property.
 */
export const selectPlayerInfo = (state) => state.player.info
