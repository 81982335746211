import { GAME_STATUS } from '@constants/GameConst'
import PropTypes from 'prop-types'

const StatusTick = ({ gameStatus, tick }) => {
  const { TICK, TICK_MIDWAY, CRASH } = GAME_STATUS
  if (![TICK, TICK_MIDWAY, CRASH].includes(gameStatus)) {
    return null
  }
  const isCrash = gameStatus === CRASH

  return (
    <div className="font-black-ops-one absolute inset-0 flex -translate-y-10 transform flex-col items-center justify-center font-normal @container">
      {tick ? (
        <div className="text-3xl @2xl:text-5xl">
          {tick}
          <span className="text-2xl">x</span>
        </div>
      ) : null}
      {isCrash ? <div className="text-base @2xl:text-3xl">CRASH</div> : null}
    </div>
  )
}

StatusTick.propTypes = {
  gameStatus: PropTypes.number,
  tick: PropTypes.string,
}

export default StatusTick
