import { rootEpic } from '@/app/rootEpic'
import app from '@/features/app/slice'
import auth from '@/features/auth/slice'
import crash from '@/features/game-crash/slice'
import notifications from '@/features/notifications/slice'
import player from '@/features/player/slice'
import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'

const epicMiddleware = createEpicMiddleware({
  dependencies: {},
})

const store = configureStore({
  reducer: {
    app,
    auth,
    player,
    notifications,
    crash,
  },
  devTools: process.env.NODE_ENV === 'development',
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(epicMiddleware),
  enhancers: [applyMiddleware(epicMiddleware)],
})

epicMiddleware.run(rootEpic)

export default store
