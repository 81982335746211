import { createSlice } from '@reduxjs/toolkit'
import React from 'react'

const initialState = {
  isLoggedIn: false,
  loading: false,
  profile: null,
}
export const isLoggedIn = React.createRef(false)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state) => {
      state.loading = true
    },
    signInFulfilled: (state) => {
      state.loading = false
    },
    signInRejected: (state) => {
      state.loading = false
    },
    signOut: () => {},
    signOutFulfilled: (state) => {
      state.isLoggedIn = false
    },
    signUp: (state) => {
      state.loading = true
    },
    signUpFulfilled: (state) => {
      state.loading = false
    },
    signUpRejected: (state) => {
      state.loading = false
    },
    getProfile: (state) => {},
    getProfileFulfilled: (state, action) => {
      isLoggedIn.current = true
      state.isLoggedIn = true
      state.profile = action.payload
    },
    getProfileRejected: (state) => {},
    signInExternal: (state) => {
      state.loading = true
    },
    signInExternalFulfilled: (state) => {
      isLoggedIn.current = true
      state.isLoggedIn = true
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  signIn,
  signInFulfilled,
  signInRejected,
  signOut,
  signOutFulfilled,
  signUp,
  signUpFulfilled,
  signUpRejected,
  getProfile,
  getProfileFulfilled,
  getProfileRejected,
  signInExternal,
  signInExternalFulfilled,
} = authSlice.actions

export default authSlice.reducer

export const selectLoading = (state) => state.auth.loading
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn
export const selectProfile = (state) => state.auth.profile
