let transactionID = 0

export const pend = (action, type) => ({
  ...action,
  meta: {
    transaction: {
      type,
      state: 'pending',
      id: `${++transactionID}`,
    },
  },
})

export const fulfill = (action, meta) => ({
  ...action,
  meta: {
    transaction: {
      state: 'fulfilled',
      ...meta.transaction,
    },
  },
})

export const selectTransaction = (action) => action?.meta?.transaction
