import { selectNotifications } from '@/features/notifications/slice'
import React from 'react'
import { useSelector } from 'react-redux'

const NotificationComponent = () => {
  const notifications = useSelector(selectNotifications)

  return (
    <div className="toast">
      {notifications.map((notification) => (
        <div key={notification.id} className="alert alert-success">
          <span>{notification.message}</span>
        </div>
      ))}
    </div>
  )
}

export default NotificationComponent
