import { Button } from '@/@components/ui/button'
import { Input } from '@/@components/ui/input'
import PropTypes from 'prop-types'

const InputBet = ({ children }) => {
  return <div className="flex gap-2">{children}</div>
}

InputBet.propTypes = {
  children: PropTypes.node,
}

InputBet.defaultProps = {
  children: null,
}

InputBet.displayName = 'InputBet'

InputBet.Input = Input
InputBet.Button = Button

export default InputBet
