import { Avatar, AvatarFallback } from '@/@components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/@components/ui/dropdown-menu'
import { Label } from '@/@components/ui/label'
import { Switch } from '@/@components/ui/switch'
import {
  selectConfig,
  setDevMode,
  setIframeMode,
  updateOverlay,
} from '@/features/app/slice'
import { selectIsLoggedIn, selectProfile, signOut } from '@/features/auth/slice'
import { OVERLAYS } from '@/shared/constants/AppConst'
import ButtonIcon from '@components/ButtonIcon'
import { ChevronDown, LogOut } from 'lucide-react'
import { isNonEmptyString } from 'ramda-adjunct'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const Header = () => {
  const [, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const profile = useSelector(selectProfile)
  const config = useSelector(selectConfig)

  const onChangeDev = (checked) => {
    dispatch(setDevMode(checked))
  }

  const onChangeIframe = (checked) => {
    dispatch(setIframeMode(checked))
  }

  const renderDevMode = () => {
    return (
      <>
        <Label htmlFor="dev-mode">Toggle Dev Mode</Label>
        <Switch
          id="dev-mode"
          checked={config.dev}
          onCheckedChange={onChangeDev}
        />
      </>
    )
  }

  const renderIframeMode = () => (
    <>
      <Label htmlFor="iframe-mode">Toggle Iframe Mode</Label>
      <Switch
        id="iframe-mode"
        checked={config.iframe}
        onCheckedChange={onChangeIframe}
      />
    </>
  )

  const renderGuest = () => {
    function handleSignIn() {
      setSearchParams({ overlay: OVERLAYS.SIGN_IN })
      dispatch(updateOverlay(OVERLAYS.SIGN_IN))
    }

    function handleSignUp() {
      setSearchParams({ overlay: OVERLAYS.SIGN_UP })
      dispatch(updateOverlay(OVERLAYS.SIGN_UP))
    }

    return (
      <header className="shadow-md shadow-black/5">
        <div className="flex items-center justify-between p-6 py-3">
          <div className="flex">
            <a
              className="mx-2 my-1 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 lg:mb-0 lg:mt-0"
              href="#">
              <img
                className="mr-2"
                src="https://tecdn.b-cdn.net/img/logo/te-transparent-noshadows.webp"
                style={{ height: '20px' }}
                alt="TE Logo"
                loading="lazy"
              />
            </a>
          </div>
          <nav className="top-0 flex w-fit gap-3">
            <button
              className="btn btn-primary btn-outline rounded-xl"
              onClick={handleSignIn}>
              Sign in
            </button>
            <button
              className="btn btn-primary rounded-xl"
              onClick={handleSignUp}>
              Sign up
            </button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ButtonIcon Icon={ChevronDown} />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuItem className="flex justify-between">
                  {renderDevMode()}
                </DropdownMenuItem>
                <DropdownMenuItem className="flex justify-between">
                  {renderIframeMode()}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </nav>
        </div>
      </header>
    )
  }

  const renderLoggedIn = () => {
    const twoChars = isNonEmptyString(profile?.email)
      ? profile.email.substring(0, 2).toUpperCase()
      : ''
    function handleSignOut() {
      dispatch(signOut())
    }

    return (
      <header className="bg-header">
        <nav className="relative flex w-full flex-wrap items-center justify-between py-2 text-neutral-500 lg:py-4">
          <div className="flex w-full flex-wrap items-center justify-between px-3">
            <div className="flex">
              <a
                className="mx-2 my-1 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 lg:mb-0 lg:mt-0"
                href="#">
                <img
                  className="mr-2"
                  src="https://tecdn.b-cdn.net/img/logo/te-transparent-noshadows.webp"
                  style={{ height: '20px' }}
                  alt="TE Logo"
                  loading="lazy"
                />
              </a>
            </div>

            <div className="flex gap-4">
              <Avatar>
                <AvatarFallback>{twoChars}</AvatarFallback>
              </Avatar>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <ButtonIcon Icon={ChevronDown} />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuItem className="flex justify-between">
                    {renderDevMode()}
                  </DropdownMenuItem>
                  <DropdownMenuItem className="flex justify-between">
                    {renderIframeMode()}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="flex justify-between"
                    onClick={handleSignOut}>
                    <span>Log out</span>
                    <LogOut className="mr-2 h-4 w-4" />
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </nav>
      </header>
    )
  }

  const renderContent = () => {
    return isLoggedIn ? renderLoggedIn() : renderGuest()
  }

  return renderContent()
}

export default Header
