import { cn } from '@/utilities'
import PropTypes from 'prop-types'

const Container = ({ className, full, children }) => {
  if (!children) {
    return null
  }
  return <div className={cn({ 'px-4': !full }, className)}>{children}</div>
}

Container.propTypes = {
  full: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

Container.defaultProps = {
  full: false,
  className: '',
}

export default Container
