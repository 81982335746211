import random from 'random'
import { STATUS } from './ChartConst'

export const customText = {
  id: 'customText',
  afterDraw: (chart, args, options) => {
    const { ctx, canvas } = chart

    const parent = chart.parent

    if (parent.status === STATUS.INIT && parent.count) {
      drawCountDownText(chart, parent.count)
      return
    }

    if (parent.status === STATUS.PLAY) {
      const points = chart.data.datasets[0].custom.points
      points.forEach((n, index) => {
        if (!n.list.length) {
          return
        }

        if (n.dataText > 0) {
          n.dataText -= random.float(0.1, 0.3)
          const dataPoint = chart.getDatasetMeta(0).data[index]
          // const raw = dataPoint.$context.raw
          // const newRaw = raw + raw * n.list[0].height
          // const y = Math.max(chart.scales.y.getPixelForValue(newRaw), dataPoint.y)
          const x = dataPoint.x
          const y = chart.scales.y.getPixelForValue(n.dataText)

          ctx.save()

          ctx.textAlign = 'center'
          ctx.font = `20px Arial`
          ctx.fillStyle = 'black'
          ctx.fillText(n.list[0].name, x, y)
          ctx.restore()
        } else {
          n.list = []
        }
      })

      return
    }

    if (parent.status === STATUS.END) {
      drawResult(chart, chart.data.datasets[0].data.at(-1))
    }
  },
}

function drawCountDownText(chart, seconds) {
  const { canvas, ctx } = chart

  // Draw the dynamic text
  ctx.fillStyle = 'black' // Set the text color
  ctx.font = '20px Arial' // Set the font size and family

  const text = `Game start in ${seconds}s`
  const textWidth = ctx.measureText(text).width
  const x = (canvas.width - textWidth) / 2
  const y = canvas.height / 2

  ctx.fillText(text, x, y)
}

function drawResult(chart, point) {
  const { canvas, ctx } = chart

  // Draw the dynamic text
  const text = `Game end at ${point}s`
  const textWidth = ctx.measureText(text).width
  const x = (canvas.width - textWidth) / 2
  const y = canvas.height / 2

  ctx.fillStyle = 'black' // Set the text color
  ctx.font = '20px Arial' // Set the font size and family
  ctx.fillText(text, x, y)
}

// Function to generate random names
export function generateRandomName() {
  const names = [
    'Alice',
    'Bob',
    'Charlie',
    'David',
    'Eve',
    'Frank',
    'Grace',
    'Henry',
    'Ivy',
    'Jack',
  ]
  return names[Math.floor(Math.random() * names.length)]
}

// Function to create a random people list with a maximum of 5 names
export function createRandomPeopleList() {
  const numPeople = Math.ceil(Math.random() * 1) // Random number of people (1 to 5)
  const peopleList = []
  for (let i = 0; i < numPeople; i++) {
    peopleList.push(generateRandomName())
  }
  return peopleList
}
