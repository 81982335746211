import { Button } from '@/@components/ui/button'
import { ButtonLink } from '@/@components/ui/button-link'
import { updateOverlay } from '@/features/app/slice'
import { OVERLAYS } from '@constants/AppConst'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const AuthPanel = () => {
  const [, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  function handleSignIn() {
    setSearchParams({ overlay: OVERLAYS.SIGN_IN })
    dispatch(updateOverlay(OVERLAYS.SIGN_IN))
  }

  function handleSignUp() {
    setSearchParams({ overlay: OVERLAYS.SIGN_UP })
    dispatch(updateOverlay(OVERLAYS.SIGN_UP))
  }

  return (
    <div className="flex flex-col items-center gap-2 rounded-md border-2 bg-card p-4 @container">
      <Button className="min-w-[200px]" onClick={handleSignUp}>
        Sign Up
      </Button>
      <div>
        Already have an account?&nbsp;
        <ButtonLink onClick={handleSignIn}>Sign In</ButtonLink>
      </div>
    </div>
  )
}

export default AuthPanel
