export const GAME_SOCKET_EVENTS = {
  JOIN: 'join',
  STARTED: 'started',
  TICK: 'tick',
}

export const GAME_SOCKET_EMITS = {
  BET: 'bet',
  CASH_OUT: 'cash_out',
}

export const GAME_STATUS = {
  NIL: 0,
  START: 1,
  TICK: 2,
  TICK_MIDWAY: 3,
  CRASH: 4,
}

export const BUTTON_STATES = {
  NIL: 'NIL',
  BET: 'BET',
  BET_COMMITTING: 'BET_COMMITTING',
  BET_COMMITTED: 'BET_COMMITTED',
  BET_NEXT_ROUND: 'BET_NEXT_ROUND',
  BET_COMMITTING_NEXT_ROUND: 'BET_COMMITTING_NEXT_ROUND',
  BET_COMMITTED_NEXT_ROUND: 'BET_COMMITTED_NEXT_ROUND',
  CASH_OUT: 'CASH_OUT',
  CASH_OUT_COMMITTING: 'CASH_OUT_COMMITTING',
  CASH_OUT_COMMITTED: 'CASH_OUT_COMMITTED',
}

export const BET_FORM_PROCESS = {
  IDLE: 0,
  COMMITTING: 1,
  COMMITTED: 3,
  COMMITTING_NEXT_ROUND: 2,
  COMMITTED_NEXT_ROUND: 4,
  ABORT_NEXT_ROUND: 5,
}
