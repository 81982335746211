export const STATUS = {
  INIT: 0,
  PLAY: 1,
  PAUSE: 2,
  END: 3,
}

export const OPTIONS = {
  SCALES_X_MAX_TICKS_LIMIT: 4,
  SCALES_Y_MIN: 0,
  SCALES_Y_MAX: 60,
  SCALES_Y_TICKS_STEP_SIDE: 10,
  SCALES_Y_MAX_TICKS_LIMIT: 4,
  INIT_CHART_DATA_THRESHOLD: 2,
  MIN_SPACE_RANGE_TO_CURRENT: 3,
  TICK_STEP: 150,
}
