import { Chart } from 'chart.js'
import { interval, map, take } from 'rxjs'
import { OPTIONS, STATUS } from './ChartConst'
import './CustomLineController'
import './CustomLineElement'
import { customText } from './custom-text'
import { getGradient } from './gradient'
import Utils from './utils'

const previousY = (context) =>
  context.index === 0
    ? undefined // ? ctx.chart.scales.y.getPixelForValue(10)
    : context.chart
        .getDatasetMeta(context.datasetIndex)
        .data[context.index - 1].getProps(['y'], true).y

class GamePlay {
  constructor(canvasElement) {
    const ctx = canvasElement.getContext('2d')
    this.ctx = ctx
    globalThis.ctx = ctx
    globalThis.gamePlay = this
    this.status = STATUS.INIT
    this.count = NaN
    this.countdownSubscription = null
    this.playSubscription = null

    this.chart = GamePlay.createChart(ctx, this)
    // this.chart = GamePlay.createRealTimeChart(canvasElement)
  }

  static createChart(ctx, parent) {
    return new Chart(ctx, {
      type: 'line',
      // type: 'custom-line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Real-time Data',
            borderColor: function (context) {
              const chart = context.chart
              const { ctx, chartArea } = chart

              if (!chartArea) {
                // This case happens on initial chart load
                return
              }
              return getGradient(ctx, chartArea)
            },
            backgroundColor: 'transparent',
            data: [],
            fill: 'origin',
            borderWidth: 4,
            // pointStyle: false,
            lineTension: 0.4,
            custom: {
              points: [],
            },
          },
        ],
      },
      options: {
        responsive: true,
        animation: {
          duration: 1000, // Set the initial animation duration
          easing: 'easeOutCubic',
          y: {
            type: 'number',
            easing: 'linear',
            from: previousY,
          },
        },
        // animation: animation,
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function (val, index, ticks) {
                // Hide every 2nd tick label
                return index % 2 === 0 ? this.getLabelForValue(val) : ''
              },
              color: Utils.CHART_COLORS.blue,
              maxTicksLimit: OPTIONS.SCALES_X_MAX_TICKS_LIMIT,
            },
          },
          y: {
            // beginAtZero: true,
            grid: {
              display: false,
            },
            min: OPTIONS.SCALES_Y_MIN,
            max: OPTIONS.SCALES_Y_MAX,
            ticks: {
              stepSize: OPTIONS.SCALES_Y_TICKS_STEP_SIDE,
              maxTicksLimit: OPTIONS.SCALES_Y_MAX_TICKS_LIMIT,
            },
          },
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
          custom: {
            parent,
          },
        },
      },
      plugins: [customText],
    })
  }

  // static createRealTimeChart(canvasElement) {
  //   const chart = new CustomChart(canvasElement, {
  //     type: 'line',
  //     data: {
  //       datasets: [
  //         {
  //           data: [],
  //         },
  //       ],
  //     },
  //     options: {
  //       scales: {
  //         x: {
  //           type: 'realtime',
  //           realtime: {
  //             delay: 2000,
  //             // onRefresh: function (chart) {
  //             //   chart.data.datasets.forEach(function (dataset) {
  //             //     dataset.data.push({
  //             //       x: Date.now(),
  //             //       y: Utils.rand(-100, 100),
  //             //     })
  //             //   })
  //             // },
  //           },
  //         },
  //         // plugins: {
  //         //   tooltip: {
  //         //     enabled: false,
  //         //   },
  //         //   legend: {
  //         //     display: false,
  //         //   },
  //         // },
  //       },
  //       interaction: {
  //         intersect: false,
  //       },
  //     },
  //   })
  //   return chart
  // }

  start() {
    this.countDown()
  }

  play(data$) {
    this.status = STATUS.PLAY
    const chart = this.chart
    this.playSubscription = data$.subscribe({
      next: (data) => {
        data = [data]
        data.forEach(({ dataPoint, peopleList }) => {
          const time = new Date().toLocaleTimeString() // Use timestamps or other data for x-axis labels

          chart.data.labels.push(time)
          chart.data.datasets[0].data.push(dataPoint)

          const customPoint = {
            list: peopleList.map((n) => ({
              name: n,
              height: 1, // in range [0, 1] height will decrease from 100% -> 0%
            })),
            dataText: dataPoint,
          }
          chart.options.scales.y.max = Utils.ceil(
            dataPoint,
            chart.options.scales.y.max
          )
          chart.data.datasets[0].custom.points.push(customPoint)
        })

        // Update the chart with animation
        chart.update({
          duration: 500, // Set the animation duration for the real-time update (in milliseconds)
          lazy: true, // Disable lazy update to force the chart to animate the entire dataset
        })
      },
      complete: () => {
        this.status = STATUS.END
        this.chart.render()
      },
    })
  }

  destroy() {
    if (this.chart) {
      this.countdownSubscription?.unsubscribe?.()
      this.playSubscription?.unsubscribe?.()
      this.chart.notifyPlugins('destroy')
      this.chart.destroy()
    }
  }

  countDown() {
    // Countdown from 5 to 0
    const countDownSeconds = 5

    // Create an observable that emits a value every second
    const countdown$ = interval(1000).pipe(
      map((tick) => countDownSeconds - tick),
      take(countDownSeconds + 1)
    )

    // Subscribe to the countdown observable
    this.countdownSubscription = countdown$.subscribe({
      next: (seconds) => {
        this.count = seconds
        this.chart.render()
      },
      complete: () => {
        // The countdown is complete, you can start the game or perform any other action here
      },
    })
  }
}

export default GamePlay
