import PropTypes from 'prop-types'

const StatusCountDown = ({ count }) => {
  if (!count) {
    return null
  }
  return (
    <div className="font-black-ops-one absolute inset-0 flex flex-col items-center justify-center font-normal @container">
      <div className="text-base @2xl:text-3xl">Preparing Round</div>
      <div className="mt-2 flex text-center text-xs uppercase text-base-400 @2xl:text-lg">
        Starting in&nbsp;
        <span className="w-10 text-left">{count}s</span>
      </div>
    </div>
  )
}

StatusCountDown.propTypes = {
  count: PropTypes.string,
}

export default StatusCountDown
