import random from 'random'
import { interval, map, take, tap } from 'rxjs'

function generateSource() {
  const count = random.int(10, 50)
  console.log('inspect.chart.count', count)
  const data$ = interval(1000).pipe(
    take(count),
    map(() => {
      const dataPoint = point.defaultFunction()
      const peopleList = createRandomPeopleList()
      return { peopleList, dataPoint }
    }),
    tap((v) => console.log('inspect.point', v.dataPoint))
    // bufferCount(10)
  )
  return data$
}

// Function to generate random names
function generateRandomName() {
  const names = [
    'Alice',
    'Bob',
    'Charlie',
    'David',
    'Eve',
    'Frank',
    'Grace',
    'Henry',
    'Ivy',
    'Jack',
  ]
  return names[Math.floor(Math.random() * names.length)]
}

// Function to create a random people list with a maximum of 5 names
function createRandomPeopleList() {
  const numPeople = Math.ceil(Math.random() * 1) // Random number of people (1 to 5)
  const peopleList = []
  for (let i = 0; i < numPeople; i++) {
    peopleList.push(generateRandomName())
  }
  return peopleList
}

// Function to generate random data points
function generateRandomDataPoint() {
  return Math.random() * 100 // Modify this based on your data requirements
}

function generateSinusoidalDataPoint() {
  const currentTime = new Date().getTime() // Get current time as a timestamp
  const frequency = 0.1 // Adjust the frequency to control the number of cycles
  const amplitude = 50 // Adjust the amplitude to control the height of the waves
  return amplitude * Math.sin(frequency * currentTime)
}

function generateRandomDataPointCustom() {
  // let init = random.float(5, 7)
  let v = 0
  return () => {
    const point = v
    v = v + random.float(2, 5)
    return point
  }
}

const point = {
  defaultFunction: generateRandomDataPointCustom(),
  function: {
    generateRandomDataPoint,
    generateSinusoidalDataPoint,
  },
  generateSource,
}

export default point
