import { createSlice } from '@reduxjs/toolkit'

const initialState = { messages: [] }

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, { payload }) => {
      state.messages = [...state.messages, payload]
    },
    removeNotification: (state, { payload }) => {
      state.messages = state.messages.filter((n) => n.id !== payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { addNotification, removeNotification } = notificationSlice.actions

export default notificationSlice.reducer

export const selectNotifications = (state) => state.notifications.messages

globalThis.addNotification = addNotification
