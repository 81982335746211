import { COOKIES } from '@constants/CookieConst'
import cookie from 'js-cookie'

export const setCookie = (key, value, expires = 1, path = '/') => {
  cookie.set(key, value, {
    expires,
    path,
    // safari iOS not allowed
    // sameSite: 'None',
    // secure: true,
  })
}

export const removeCookie = (key) => {
  cookie.remove(key, {
    expires: 1,
  })
}

export const getCookie = (key) => {
  return getCookieFromBrowser(key)
}

const getCookieFromBrowser = (key) => {
  return cookie.get(key)
}

export function getToken(isPure = false) {
  const jwtToken = getCookie(COOKIES.JWT_AUTH)
  if (jwtToken) {
    if (isPure) {
      return jwtToken
    } else {
      return `Bearer ${jwtToken}`
    }
  }
}
