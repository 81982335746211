import { Button } from '@/@components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/@components/ui/form'
import { selectConfig } from '@/features/app/slice'
import { createGameEvent } from '@/features/game-crash/actions'
import { updateCash } from '@/features/player/slice'
import { socket } from '@/features/socket'
import InputBet from '@components/InputBet'
import {
  BET_FORM_PROCESS,
  BUTTON_STATES,
  GAME_STATUS,
} from '@constants/GameConst'
import { zodResolver } from '@hookform/resolvers/zod'
import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  delay,
  filter,
  from,
  map,
  merge,
  of,
  race,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs'
import * as z from 'zod'
import { CRASH_EVENTS, CRASH_GAME_ERROR } from '../utils/constant'

const formSchema = z.object({
  amount: z.coerce.number().min(1).max(1000),
})

const getVariant = (timeline, process) => {
  let variant = BUTTON_STATES.NIL
  const { NIL, START, TICK, TICK_MIDWAY, CRASH } = GAME_STATUS

  if ([START, CRASH].includes(timeline)) {
    if (process === BET_FORM_PROCESS.IDLE) {
      variant = BUTTON_STATES.BET
    } else if (process === BET_FORM_PROCESS.COMMITTING) {
      variant = BUTTON_STATES.BET_COMMITTING
    } else if (process === BET_FORM_PROCESS.COMMITTED_NEXT_ROUND) {
      variant = BUTTON_STATES.BET_COMMITTED_NEXT_ROUND
    } else {
      variant = BUTTON_STATES.BET_COMMITTED
    }
  } else if (timeline === TICK) {
    if (process === BET_FORM_PROCESS.IDLE) {
      variant = BUTTON_STATES.BET_NEXT_ROUND
    } else if (process === BET_FORM_PROCESS.COMMITTING_NEXT_ROUND) {
      variant = BUTTON_STATES.BET_COMMITTING_NEXT_ROUND
    } else if (process === BET_FORM_PROCESS.COMMITTED_NEXT_ROUND) {
      variant = BUTTON_STATES.BET_COMMITTED_NEXT_ROUND
    } else if (process === BET_FORM_PROCESS.COMMITTING) {
      variant = BUTTON_STATES.CASH_OUT_COMMITTING
    } else if (process === BET_FORM_PROCESS.COMMITTED) {
      variant = BUTTON_STATES.CASH_OUT
    }
  } else if ([NIL, TICK_MIDWAY].includes(timeline)) {
    if (process === BET_FORM_PROCESS.IDLE) {
      variant = BUTTON_STATES.BET_NEXT_ROUND
    } else if (process === BET_FORM_PROCESS.COMMITTING_NEXT_ROUND) {
      variant = BUTTON_STATES.BET_COMMITTING_NEXT_ROUND
    } else if (process === BET_FORM_PROCESS.COMMITTED_NEXT_ROUND) {
      variant = BUTTON_STATES.BET_COMMITTED_NEXT_ROUND
    }
  }

  return variant
}

/**
 * A form component for placing bets.
 *
 * @typedef {import('react').FC<{
 *   gameStatus: typeof GAME_STATUS[keyof typeof import('@/shared/constants/GameConst').GAME_STATUS]
 *   gameStatus$: Observable<typeof GAME_STATUS[keyof typeof import('@/shared/constants/GameConst').GAME_STATUS]>
 *   firstSessionTick$: Observable<{ tick: number }>
 *   onCashOut: () => void
 *   onBetFail: (error) => void
 * }>} FC
 *
 * @type {FC}
 */
const BetForm = ({ gameStatus, gameStatus$, onCashOut, onBetFail }) => {
  const dispatch = useDispatch()
  const config = useSelector(selectConfig)
  const refBet = useRef(new Subject())
  const refBetNextRound = useRef(new Subject())
  const refCashOut = useRef(new Subject())
  const [process, setProcess] = useState(BET_FORM_PROCESS.IDLE)
  const refProcess = useRef(new BehaviorSubject(BET_FORM_PROCESS.IDLE))

  const variant = getVariant(gameStatus, process)

  useEffect(
    function betEffect() {
      const getInRound$ = () => {
        return refBet.current.pipe(
          switchMap((data) =>
            gameStatus$.pipe(
              filter((v) => v === GAME_STATUS.START),
              take(1),
              map(() => data)
            )
          )
        )
      }

      const getNextRound$ = () => {
        const gameStarted$ = gameStatus$.pipe(
          filter((v) => v === GAME_STATUS.START),
          take(1)
        )

        const abort$ = refProcess.current.pipe(
          filter((v) => v === BET_FORM_PROCESS.ABORT_NEXT_ROUND),
          tap(() => {
            setProcess(BET_FORM_PROCESS.IDLE)
            refProcess.current.next(BET_FORM_PROCESS.IDLE)
          }),
          switchMap(
            // swallow error to make endless flow
            () => throwError(() => new Error('player_abort')).pipe(() => EMPTY)
          )
        )

        return refBetNextRound.current.pipe(
          switchMap((data) =>
            // we may wait for at most 1000ms until the game starts
            race(
              of(data).pipe(
                delay(1000),
                tap(() => {
                  setProcess(BET_FORM_PROCESS.COMMITTED_NEXT_ROUND)
                  refProcess.current.next(BET_FORM_PROCESS.COMMITTED_NEXT_ROUND)
                }),
                switchMap(() => gameStarted$)
              ),
              gameStarted$,
              abort$
            ).pipe(map(() => data))
          )
        )
      }

      const inRound$ = getInRound$()
      const nextRound$ = getNextRound$()

      const su = merge(inRound$, nextRound$)
        .pipe(
          switchMap((data) =>
            from(
              new Promise((resolve, reject) => {
                const { amount } = data
                socket.emit('bet', { amount }, (result) => {
                  if (result?.validator?.status) {
                    resolve(result)
                  } else {
                    reject(result)
                  }
                })
              })
            ).pipe(
              // ? Should move error handler to epic
              catchError((err) => {
                switch (err?.status_code) {
                  case CRASH_GAME_ERROR.GameInProgress:
                  case CRASH_GAME_ERROR.AlreadyPlaceBet:
                  default:
                  // TODO: handle error
                }

                setProcess(BET_FORM_PROCESS.IDLE)
                refProcess.current.next(BET_FORM_PROCESS.IDLE)
                onBetFail(err)
                return EMPTY
              })
            )
          )
        )
        .subscribe((result) => {
          dispatch(updateCash(result?.cash))
          setProcess(BET_FORM_PROCESS.COMMITTED)
          refProcess.current.next(BET_FORM_PROCESS.COMMITTED)
        })

      return () => {
        su.unsubscribe()
      }
    },
    [dispatch, gameStatus$, onBetFail]
  )

  useEffect(
    function cashOutEffect() {
      const sub = refCashOut.current
        .pipe(
          switchMap(() =>
            from(
              new Promise((resolve, reject) =>
                socket.emit('cash_out', {}, (result) => {
                  resolve(result)
                })
              )
            ).pipe(
              catchError((err) => {
                switch (err?.status_code) {
                  // TODO: handle error
                  case CRASH_GAME_ERROR.AlreadyCashOut:
                  case CRASH_GAME_ERROR.GameAlreadyCrashed:
                  case CRASH_GAME_ERROR.NoBetPlaced:
                  default:
                }
                return EMPTY
              })
            )
          )
        )
        .subscribe((result) => {
          dispatch(updateCash(result?.cash))
        })
      return () => {
        sub.unsubscribe()
      }
    },
    [dispatch]
  )

  useEffect(function resetForm() {
    const su = createGameEvent(CRASH_EVENTS.GAME_ENDED).subscribe(() => {
      const currentProcess = refProcess.current.getValue()
      const { COMMITTING_NEXT_ROUND, COMMITTED_NEXT_ROUND } = BET_FORM_PROCESS
      if (
        [COMMITTING_NEXT_ROUND, COMMITTED_NEXT_ROUND].includes(
          currentProcess
        ) === false
      ) {
        setProcess(BET_FORM_PROCESS.IDLE)
        refProcess.current.next(BET_FORM_PROCESS.IDLE)
      }
    })

    return () => {
      su.unsubscribe()
    }
  }, [])

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: 5,
    },
  })

  const onSubmit = (data) => {
    if (variant === BUTTON_STATES.BET) {
      setProcess(BET_FORM_PROCESS.COMMITTING)
      refProcess.current.next(BET_FORM_PROCESS.COMMITTING)
      refBet.current.next(data)
      return
    }
    if (variant === BUTTON_STATES.BET_NEXT_ROUND) {
      setProcess(BET_FORM_PROCESS.COMMITTING_NEXT_ROUND)
      refProcess.current.next(BET_FORM_PROCESS.COMMITTING_NEXT_ROUND)
      refBetNextRound.current.next(data)
      return
    }
    if (variant === BUTTON_STATES.BET_COMMITTED_NEXT_ROUND) {
      setProcess(BET_FORM_PROCESS.ABORT_NEXT_ROUND)
      refProcess.current.next(BET_FORM_PROCESS.ABORT_NEXT_ROUND)
      return
    }
    if (variant === BUTTON_STATES.CASH_OUT) {
      refCashOut.current.next()
      onCashOut()
      setProcess(BET_FORM_PROCESS.IDLE)
      refProcess.current.next(BET_FORM_PROCESS.IDLE)
      return
    }
  }

  const handleHalf = () => {
    const { amount } = form.getValues()
    form.setValue('amount', amount / 2)
  }

  const handleDouble = () => {
    const { amount } = form.getValues()
    form.setValue('amount', amount * 2)
  }

  const renderButton = () => {
    let button
    switch (variant) {
      case BUTTON_STATES.BET:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit">
            Bet
          </Button>
        )
        break
      case BUTTON_STATES.BET_NEXT_ROUND:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit">
            <div className="flex flex-col">
              Bet
              <span>(Next round)</span>
            </div>
          </Button>
        )
        break
      case BUTTON_STATES.BET_COMMITTING:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit"
            disabled>
            Bet
          </Button>
        )
        break
      case BUTTON_STATES.BET_COMMITTING_NEXT_ROUND:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit"
            disabled>
            <div className="flex flex-col">
              Bet
              <span>(Next round)</span>
            </div>
          </Button>
        )
        break
      case BUTTON_STATES.BET_COMMITTED:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit"
            disabled>
            Bet accepted
          </Button>
        )
        break
      case BUTTON_STATES.BET_COMMITTED_NEXT_ROUND:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit"
            variant="destructive">
            <div className="flex flex-col">
              Bet accepted
              <span>(Next round)</span>
            </div>
          </Button>
        )
        break
      case BUTTON_STATES.CASH_OUT:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="submit">
            Cash out
          </Button>
        )
        break
      case BUTTON_STATES.CASH_OUT_COMMITTING:
      case BUTTON_STATES.CASH_OUT_COMMITTED:
        button = (
          <Button
            className="min-w-[300px] @2xl:w-fit @2xl:self-center"
            size="lg"
            type="button"
            disabled>
            Cash out
          </Button>
        )
        break
      case BUTTON_STATES.NIL:
      default:
        button = null
    }
    return button
  }

  return (
    <div className="flex flex-col gap-4 rounded-md border-2 bg-card p-4 @container">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4">
          {renderButton()}
          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Bet Amount</FormLabel>
                <FormControl>
                  <InputBet>
                    <InputBet.Input {...field} onChange={field.onChange} />
                    <InputBet.Button
                      type="button"
                      className="flex h-10 w-10 shrink-0"
                      onClick={handleHalf}>
                      1/2
                    </InputBet.Button>
                    <InputBet.Button
                      type="button"
                      className="flex h-10 w-10 shrink-0"
                      onClick={handleDouble}>
                      x2
                    </InputBet.Button>
                  </InputBet>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
      {config.dev ? (
        <pre>
          {JSON.stringify(
            {
              gameStatus: Object.keys(GAME_STATUS).find(
                (key) => GAME_STATUS[key] === gameStatus
              ),
              process: Object.keys(BET_FORM_PROCESS).find(
                (key) => BET_FORM_PROCESS[key] === process
              ),
              variant,
            },
            null,
            2
          )}
        </pre>
      ) : null}
      {/* <pre>{JSON.stringify(form.watch(), null, 2)}</pre>
      Errors
      <pre>
        {Object.keys(form.formState.errors).length > 0 &&
          JSON.stringify(
            Object.entries(form.formState.errors).reduce(
              (previous, [key, { ref, ...rest }]) => {
                previous[key] = rest
                return previous
              },
              {}
            ),
            null,
            2
          )}
      </pre>
      Touched
      <pre>
        {JSON.stringify(
          Object.keys(form.formState.touchedFields || {}),
          null,
          2
        )}
      </pre> */}
    </div>
  )
}

BetForm.propTypes = {
  gameStatus: PropTypes.oneOf(Object.values(GAME_STATUS)),
  gameStatus$: PropTypes.instanceOf(Observable),
  firstSessionTick$: PropTypes.instanceOf(Observable),
  onCashOut: PropTypes.func,
  onBetFail: PropTypes.func,
}

export default memo(BetForm)
