import { selectConfig } from '@/features/app/slice'
import NotificationComponent from '@/features/notifications/Notification'
import { SocketContext, socket } from '@/features/socket'
import ModalGlobal from '@/modules/ModalGlobal'
import { inIframe } from '@/utilities/utils-miscellaneous'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Header from './Header'
import HeaderExternal from './HeaderExternal'

const Layout = ({ children }) => {
  const config = useSelector(selectConfig)
  const isIframe = inIframe() || config.iframe

  const renderExternal = () => {
    return (
      <div className="flex min-h-screen flex-col gap-4 bg-[url('https://i.pinimg.com/originals/65/d3/2c/65d32c0b80b35558e265a8d007c98ce3.jpg')] md:bg-none">
        <HeaderExternal />
        <div className="container">{children}</div>
      </div>
    )
  }

  if (isIframe) {
    return renderExternal()
  }

  return (
    <div className="flex min-h-screen flex-col gap-4 bg-[url('https://i.pinimg.com/originals/65/d3/2c/65d32c0b80b35558e265a8d007c98ce3.jpg')] md:bg-none">
      <SocketContext.Provider value={socket}>
        <ModalGlobal />
        <Header />
        <NotificationComponent />
        <div className="container">{children}</div>
      </SocketContext.Provider>
      {/* <footer>
        <a href="https://www.freepik.com/free-vector/animation-sprite-sheet-bomb-explosion-sequence_29084609.htm#query=explosion%20sprite&position=0&from_view=keyword&track=ais">
          Image by upklyak
        </a>
        on Freepik
      </footer> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
