import { OVERLAYS } from '@constants/AppConst'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  overlay: OVERLAYS.NIL,
  aboutConfig: {
    // dev mode
    dev: false,
    // iframe mode
    iframe: false,
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startUp: (state) => {
      state.loading = true
    },
    startUpFulfilled: (state) => {
      state.loading = false
    },
    startUpRejected: (state) => {
      state.loading = false
    },
    updateOverlay: (state, action) => {
      state.overlay = action.payload
    },
    setDevMode: (state, action) => {
      state.aboutConfig = {
        ...state.aboutConfig,
        dev: action.payload,
      }
    },
    setIframeMode: (state, action) => {
      state.aboutConfig = {
        ...state.aboutConfig,
        iframe: action.payload,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  startUp,
  startUpFulfilled,
  startUpRejected,
  updateOverlay,
  setDevMode,
  setIframeMode,
} = appSlice.actions

export default appSlice.reducer

export const selectOverlay = (state) => state.app.overlay
export const selectConfig = (state) => state.app.aboutConfig
