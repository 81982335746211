import Decimal from 'decimal.js'
import { isNotValidNumber } from 'ramda-adjunct'
import clone from 'ramda/src/clone'
import curry from 'ramda/src/curry'
import lensPath from 'ramda/src/lensPath'
import reduce from 'ramda/src/reduce'
import view from 'ramda/src/view'
import setValue from 'set-value'
import { PLAYER_STATUSES, STATE_BASICS } from './constant'

/**
 * Returns a function that retrieves the value at the specified path in an object.
 *
 * @param {Array<string|number>} path The path to the value to retrieve.
 * @param {Object} obj The object to retrieve the value from.
 * @returns {any} The value at the specified path in the object.
 */
export const viewLensPath = curry((path, obj) => {
  const lens = lensPath(path)
  return view(lens, obj)
})

/**
 * Returns a function that takes an object and returns a Decimal representation of the value at the specified path, or returns a Decimal representation of the value at the specified path if an object is also provided.
 *
 * @param {Array<string|number>} path The path to the value to retrieve.
 * @returns {import('@/shared/types').DecimalJsFunction} A function that takes an object and returns a Decimal representation of the value at the specified path, or a Decimal representation of the value at the specified path if an object is also provided.
 * @throws {Error} If the value at the specified path is not a valid number.
 */
export const viewLensPathNum = (path) => (obj) => {
  const lens = lensPath(path)
  const v = view(lens, obj)
  if (isNotValidNumber(v)) {
    throw new Error('viewLensPathNumb: value is not a number')
  }
  return new Decimal(v)
}

/**
 * Returns a function that sets the value at the specified path in an object to the provided value.
 *
 * @param {Array<string|number>} path The path to the value to set.
 * @param {Object} obj The object to set the value in.
 * @returns {import('@/shared/types').MutateFunction} A function that takes a value and sets the value at the specified path in the object to the provided value.
 */
export const setVal = (path, obj) => (val) => {
  setValue(obj, path, val)
}

/**
 * Merges two objects by copying the values at the specified paths from the second object to the first object.
 *
 * @param {Array<Array<string|number>>} paths The paths to the values to merge.
 * @param {Object} left The first object to merge.
 * @param {Object} right The second object to merge.
 * @returns {Object} The merged object.
 */
export const mergeDeepLeftByPaths = (paths, left, right) => {
  const objClone = clone(left)
  paths.forEach((path) => {
    const v = viewLensPath(path, right)
    setValue(objClone, path, v)
  })
  return objClone
}

/**
 * A collection of helper functions for creating state objects with basic states.
 *
 * @typedef {Object} Stater
 * @property {StateObject} nil - Returns a state object with a "Nil" state.
 * @property {StateObject} pending - Returns a state object with a "Pending" state.
 * @property {StateObject} fulfilled - Returns a state object with a "Fulfilled" state and optional data.
 * @property {StateObject} rejected - Returns a state object with a "Rejected" state.
 * @typedef {Object} StateObject
 * @property {string} state - The state of the object, either "Nil", "Pending", "Fulfilled", or "Rejected".
 * @property {*} [data] - The data associated with the object, if any.
 * @property {*} [meta] - The metadata associated with the object, if any.
 */
export const stater = {
  empty: () => ({ state: STATE_BASICS.Empty }),
  nil: () => ({ state: STATE_BASICS.Nil }),
  pending: () => ({ state: STATE_BASICS.Pending }),
  fulfilled: (data) => ({ state: STATE_BASICS.Fulfilled, data }),
  rejected: () => ({ state: STATE_BASICS.Rejected }),
}

const PER_PAGE = 10

export const staterPager = {
  empty: () => ({ state: STATE_BASICS.Empty }),
  nil: () => ({
    state: STATE_BASICS.Nil,
    meta: { page: 1, perPage: PER_PAGE },
  }),
  pending: (meta) => ({ state: STATE_BASICS.Pending, meta }),
  fulfilled: (data, meta) => ({ state: STATE_BASICS.Fulfilled, data, meta }),
  rejected: (meta) => ({ state: STATE_BASICS.Rejected, meta }),
}

export const staterPlayerStatuses = {
  betting: () => ({ state: PLAYER_STATUSES.Betting }),
  bang: () => ({ state: PLAYER_STATUSES.Bang }),
  cashedOut: (data) => ({ state: PLAYER_STATUSES.CashedOut, data }),
}

/**
 * Concatenates two state objects into a single state object.
 *
 * @function
 * @param {StateObject} stater1 - The first state object to concatenate.
 * @param {StateObject} stater2 - The second state object to concatenate.
 * @returns {StateObject} The concatenated state object.
 */
function concatTwo(stater1, stater2) {
  if (stater1.state === STATE_BASICS.Empty) {
    return stater2
  }

  let out = stater.pending()
  if (
    stater1.state === STATE_BASICS.Rejected ||
    stater2.state === STATE_BASICS.Rejected
  ) {
    out = stater.rejected()
  } else if (
    stater1.state === STATE_BASICS.Fulfilled &&
    stater2.state === STATE_BASICS.Fulfilled
  ) {
    out = stater.fulfilled([stater1.data, stater2.data])
  } else if (
    stater1.state === STATE_BASICS.Nil &&
    stater2.state === STATE_BASICS.Nil
  ) {
    out = stater.nil()
  }
  return out
}

/**
 * Concatenates multiple state objects into a single state object.
 *
 * @function
 * @param {...StateObject} staters - The first state object to concatenate.
 * @returns {StateObject} The concatenated state object.
 */
export const concatStaters = (...staters) =>
  reduce(concatTwo, stater.empty(), staters)

export const calcGameRound = (gamePoint) => {
  return `${(gamePoint / 100).toFixed(2)}x`
}
