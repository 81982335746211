import { ThemeProvider } from '@/@components/theme-provider'
import ChartLine from '@components/ChartLine'
import PageConfigure from '@pages/configure'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { startUp } from './features/app/slice'
import PageHome from './pages/home'
import PageGameCrash from './pages/launcher/game-crash'

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageHome />,
  },
  {
    path: 'configure',
    element: <PageConfigure />,
  },
  {
    path: '/chart',
    element: <ChartLine />,
  },
  {
    path: '/launcher/game-crash',
    element: <PageGameCrash />,
  },
])

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startUp())
    return () => {}
  }, [dispatch])

  return (
    <ThemeProvider defaultTheme="dark">
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
