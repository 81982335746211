import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/@components/ui/tabs'
import { selectIsLoggedIn } from '@/features/auth/slice'
import { Suspense, lazy, memo } from 'react'
import { useSelector } from 'react-redux'

const BetHistory = lazy(() =>
  import(/* webpackChunkName: "History" */ './components/BetHistory')
)
const MyBets = lazy(() =>
  import(/* webpackChunkName: "MyBets" */ './components/MyBets')
)

const Latest = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    <div>
      <h1 className="py-4">Latest bet & Race</h1>
      <Tabs defaultValue="history">
        <TabsList>
          {isLoggedIn ? (
            <TabsTrigger value="my_bets">My bets</TabsTrigger>
          ) : null}
          <TabsTrigger value="history">History</TabsTrigger>
        </TabsList>
        {isLoggedIn ? (
          <Suspense fallback={<div>Loading...</div>}>
            <TabsContent value="my_bets">
              <MyBets />
            </TabsContent>
          </Suspense>
        ) : null}
        <Suspense fallback={<div>Loading...</div>}>
          <TabsContent value="history">
            <BetHistory />
          </TabsContent>
        </Suspense>
      </Tabs>
    </div>
  )
}

export default memo(Latest)
