import GameCrash from '@/features/game-crash'
import Layout from '@/layout'

const PageHome = () => {
  return (
    <Layout>
      <GameCrash />
    </Layout>
  )
}

export default PageHome
