import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/@components/ui/table'
import Container from '@components/Container'
import { isNumber } from 'ramda-adjunct'
import { useCurrentBetHistory } from '../hooks/useCurrentBetHistory'
import { PLAYER_STATUSES, STATE_BASICS } from '../utils/constant'

const PlayerTable = () => {
  const currentBetHistory = useCurrentBetHistory()

  const getStatus = (player) => {
    switch (player._status.state) {
      case PLAYER_STATUSES.Betting:
        return 'Betting'
      case PLAYER_STATUSES.Bang:
        return 'Bang'
      case PLAYER_STATUSES.CashedOut:
        return player?._status?.data
      default:
        return null
    }
  }

  const toAmount = (amount, currency) =>
    isNumber(amount) && currency ? `${amount} ${currency}` : null

  const renderContent = () => {
    if (currentBetHistory.state === STATE_BASICS.Fulfilled) {
      const [history, playerInfo] = currentBetHistory.data
      if (history.length === 0) {
        return (
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                No players
              </TableCell>
            </TableRow>
          </TableBody>
        )
      }

      return (
        <TableBody>
          {history.map((p) => {
            return (
              <TableRow key={p?.user?.id}>
                <TableCell className="font-medium">
                  <div className="max-w-[100px] truncate @md:max-w-none">
                    {p?.user?.display_name}
                  </div>
                </TableCell>
                <TableCell>{getStatus(p)}</TableCell>
                <TableCell>
                  {toAmount(p?.betAmount, playerInfo?.currency)}
                </TableCell>
                <TableCell className="text-right">
                  {toAmount(p?.profit, playerInfo?.currency)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      )
    }
    return null
  }

  return (
    <Container className="shrink-0 grow-0 basis-2/5 px-4 lg:px-0">
      <div className="min-h-[300px] rounded-md border-2 bg-card px-4 @container">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Player</TableHead>
              <TableHead>Cash Out</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead className="text-right">Profit</TableHead>
            </TableRow>
          </TableHeader>
          {renderContent()}
        </Table>
      </div>
    </Container>
  )
}

export default PlayerTable
