import {
  addNotification,
  removeNotification,
} from '@/features/notifications/slice'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { delay, mapTo, mergeMap } from 'rxjs/operators'

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const addNotificationEpic = (action$, _state$) =>
  action$.pipe(
    ofType(addNotification.type),
    mergeMap((action) =>
      of(action).pipe(delay(5000), mapTo(removeNotification(action.payload.id)))
    )
  )

const notificationsEpics = [addNotificationEpic]

export default notificationsEpics
