export const OVERLAYS = {
  NIL: '',
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
}

export const QUERY_STRINGS = {
  OVERLAY: 'overlay',
}

export const ERROR_TYPE = {
  API_FRONTEND_INVALID: 0,
  API_BACKEND_INVALID: 1,
}
