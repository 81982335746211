import { Chart, registerables } from 'chart.js'
import { useEffect, useRef } from 'react'
import GamePlay from './game-play'
import point from './getPoint'

Chart.register(...registerables)

const RealTimeChart = () => {
  const refCanvas = useRef(null)
  const refGame = useRef(null)

  useEffect(() => {
    if (refCanvas.current) {
      const game = new GamePlay(refCanvas.current)
      refGame.current = game
    }

    return () => {
      refGame.current?.destroy?.()
      refGame.current = null
    }
  }, [])

  const handleStart = () => {
    refGame.current.start()
  }

  const handlePlay = () => {
    const data$ = point.generateSource()
    refGame.current.play(data$)
  }

  const handleReset = () => {
    refGame.current?.destroy?.()
    const ctx = refCanvas.current.getContext('2d')
    refGame.current = new GamePlay(ctx)
  }

  return (
    <div>
      <canvas ref={refCanvas} width="400" height="200" />
      <button onClick={handleStart}>Start</button>
      <button onClick={handlePlay}>Play</button>
      <button onClick={handleReset}>Reset</button>
      <button>Pause</button>
    </div>
  )
}

export default RealTimeChart
