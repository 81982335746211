export const CRASH_EVENTS = {
  // Socket events
  JOIN: 'join',

  // Game events
  GAME_STARTING: 'gameStarting',
  GAME_TICK: 'gameTick',
  GAME_ENDED: 'gameEnded',
  CASHED_OUT: 'cashedOut',
  PLAYERS: 'players',
}

export const GAME_TICK = 150 // 150 milliseconds

export const STATE_BASICS = {
  Empty: 'Empty',
  Nil: 'Nil',
  Pending: 'Pending',
  Fulfilled: 'Fulfilled',
  Rejected: 'Rejected',
}

// sync from server
export const PLAY_STATUSES = {
  0: 'betting',
  1: 'bang',
}

// sync from server
export const CRASH_GAME_ERROR = {
  // cash out errors
  NoBetPlaced: 3050, // can not cash out
  GameAlreadyCrashed: 3051, // cannot cash out
  AlreadyCashOut: 3054, // can not cash out again

  // bet errors
  GameInProgress: 3052, // can not bet
  AlreadyPlaceBet: 3053, // can not place bet again
}

export const PLAYER_STATUSES = {
  Betting: 0,
  Bang: 1,
  CashedOut: 2,
}
