import {
  setDevMode,
  setIframeMode,
  startUp,
  startUpFulfilled,
  startUpRejected,
  updateOverlay,
} from '@/features/app/slice'
import { getQueryString } from '@/features/auth/history'
import { getProfile, getProfileFulfilled } from '@/features/auth/slice'
import { getCookie } from '@/utilities/cookie'
import { pend, selectTransaction } from '@/utilities/utils-action'
import { OVERLAYS, QUERY_STRINGS } from '@constants/AppConst'
import { COOKIES } from '@constants/CookieConst'
import { LOCAL_STORAGE } from '@constants/LocalStorageConst'
import { ofType } from 'redux-observable'
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs'
import { catchError, filter, mergeMap, switchMap, tap } from 'rxjs/operators'

/**
 * Creates an operator that takes the first fulfilled transaction from the source observable.
 *
 * @param {string} type The type of the fulfilled action to take.
 * @param {import('rxjs').Observable} action$ The observable that emits all actions.
 * @returns {import('rxjs').OperatorFunction} The operator function.
 */
const takeFulfilledTransaction = (type, action$) => (source) =>
  new Observable((observer) => {
    const prevAction = new BehaviorSubject()
    const subscription = source
      .pipe(
        tap((action) => {
          prevAction.next(action)
        }),
        mergeMap((action) => action$.pipe(ofType(type)))
      )
      .subscribe({
        next(value) {
          console.log({ prevAction: prevAction.getValue(), action: value })
          if (value.type === 'fulfilled') {
            observer.next(value)
            observer.complete()
          }
        },
        error(err) {
          observer.error(err)
        },
        complete() {
          observer.complete()
        },
      })
    return () => {
      subscription.unsubscribe()
      prevAction.complete()
    }
  })

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const startUpEpic = (action$, _state$) =>
  action$.pipe(
    ofType(startUp.type),
    switchMap((_action) => {
      const token = getCookie(COOKIES.JWT_AUTH)
      const dev = localStorage.getItem(LOCAL_STORAGE.DEV)
      const iframe = localStorage.getItem(LOCAL_STORAGE.IFRAME)

      const actions = []

      if (dev === '1') {
        actions.push(setDevMode(true))
      }
      if (iframe === '1') {
        actions.push(setIframeMode(true))
      }

      // Handles logged in user
      if (token) {
        actions.push(pend(getProfile(), 'startUp'))
        return of(...actions)
      }

      // Handles guest user
      actions.push(startUpFulfilled())

      const overlay = getQueryString(QUERY_STRINGS.OVERLAY)
      switch (overlay) {
        case OVERLAYS.SIGN_IN:
          actions.push(updateOverlay(OVERLAYS.SIGN_IN))
          break
        case OVERLAYS.SIGN_UP:
          actions.push(updateOverlay(OVERLAYS.SIGN_UP))
          break
        default:
          break
      }
      return of(...actions)
    }),
    catchError((err) => {
      return startUpRejected(err)
    })
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const getProfileAtStartUpEpic = (action$) =>
  action$.pipe(
    ofType(getProfileFulfilled.type),
    filter((action) => {
      return selectTransaction(action).type === 'startUp'
    }),
    switchMap(() => of(startUpFulfilled()))
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const setDevModeEpic = (action$) =>
  action$.pipe(
    ofType(setDevMode.type),
    tap((action) => {
      localStorage.setItem(LOCAL_STORAGE.DEV, action.payload ? '1' : '0')
    }),
    switchMap(() => EMPTY)
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
export const setIframeModeEpic = (action$) =>
  action$.pipe(
    ofType(setIframeMode.type),
    tap((action) => {
      localStorage.setItem(LOCAL_STORAGE.IFRAME, action.payload ? '1' : '0')
    }),
    switchMap(() => EMPTY)
  )

const appEpics = [
  startUpEpic,
  getProfileAtStartUpEpic,
  setDevModeEpic,
  setIframeModeEpic,
]

export default appEpics
