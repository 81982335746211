import { selectConfig } from '@/features/app/slice'
import { signInExternal } from '@/features/auth/slice'
import GameCrash from '@/features/game-crash'
import { getPlayerInfo } from '@/features/player/slice'
import Layout from '@/layout'
import { inIframe } from '@/utilities/utils-miscellaneous'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const PageGameCrash = () => {
  const config = useSelector(selectConfig)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const isIframe = inIframe() || config.iframe

  useEffect(() => {
    const integration_token = searchParams.get('integration_token')
    const game_code = searchParams.get('game_code')
    const game_token = searchParams.get('game_token')
    const isValidSignIn =
      isIframe && integration_token && game_code && game_token

    if (isValidSignIn) {
      dispatch(signInExternal({ integration_token, game_code, game_token }))
      dispatch(getPlayerInfo())
    }

    return () => {}
  }, [dispatch, isIframe, searchParams])

  return (
    <Layout>
      <GameCrash />
    </Layout>
  )
}

export default PageGameCrash
