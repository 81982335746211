import { cn } from '@/utilities'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Button } from './button'

export const ButtonLink = forwardRef(({ className, ...props }, ref) => {
  return (
    <Button
      variant="link"
      className={cn('p-0', className)}
      ref={ref}
      {...props}
    />
  )
})

ButtonLink.propTypes = {
  className: PropTypes.string,
}

ButtonLink.displayName = 'ButtonLink'
