import {
  getBetHistory,
  getBetHistoryFulfilled,
  getBetHistoryRejected,
  getCurrentBetHistoryFulfilled,
  getPlayerHistory,
  getPlayerHistoryFulfilled,
  getPlayerHistoryRejected,
  updateFinalPlayerStatuses,
} from '@/features/game-crash/slice'
import {
  PLAYER_STATUSES,
  STATE_BASICS,
} from '@/features/game-crash/utils/constant'
import { staterPlayerStatuses } from '@/features/game-crash/utils/helper'
import { socket } from '@/features/socket'
import { getURL } from '@/utilities'
import { getToken } from '@/utilities/cookie'
import { inIframe } from '@/utilities/utils-miscellaneous'
import { ofType } from 'redux-observable'
import {
  Observable,
  catchError,
  concatMap,
  first,
  fromEvent,
  interval,
  map,
  of,
  switchMap,
  takeWhile,
} from 'rxjs'
import { ajax } from 'rxjs/ajax'

/**
 * Returns an Observable that makes a GET request to the current bet histories API.
 *
 * @param {Object} options - The options for the API request.
 * @param {number} options.page - The page number to retrieve.
 * @param {number} [options.perPage=10] - The number of items per page to retrieve.
 * @returns {import('rxjs').Observable<any>} An Observable that emits the response from the API request.
 */
const getBetHistoryApi = ({ page, perPage }) =>
  ajax.get(getURL(`/crash-game-histories?page=${page}&perPage=${perPage}`))

/**
 * @param {import('@/shared/types').Observable} action$
 */
const getBetHistoryEpic = (action$, state) =>
  action$.pipe(
    ofType(getBetHistory.type),
    switchMap((action) => {
      // If the action payload is not provided, then use the meta from the state.
      // Because that means fetching the first page.
      const meta = action?.payload?.meta ?? state.value.crash.betHistory.meta
      return getBetHistoryApi(meta).pipe(
        map((ajaxResponse) => {
          return getBetHistoryFulfilled({
            data: ajaxResponse.response,
            meta,
          })
        })
      )
    }),
    catchError((err) => {
      return of(getBetHistoryRejected(err))
    })
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
const getPlayerHistoryEpic = (action$, state) =>
  action$.pipe(
    ofType(getPlayerHistory.type),
    switchMap((action) => {
      // If the action payload is not provided, then use the meta from the state.
      // Because that means fetching the first page.

      const isIframe = inIframe() || state.value.app.aboutConfig.iframe
      const meta = action?.payload?.meta ?? state.value.crash.playerHistory.meta
      const { page, perPage } = meta
      return ajax
        .get(
          getURL(
            `/get-my-bet-histories?page=${page}&perPage=${perPage}&gameToken=${getToken(
              isIframe
            )}`
          )
        )
        .pipe(
          map((ajaxResponse) => {
            return getPlayerHistoryFulfilled({
              data: ajaxResponse.response,
              meta,
            })
          })
        )
    }),
    catchError((err) => {
      return of(getPlayerHistoryRejected(err))
    })
  )

/**
 * @param {import('@/shared/types').Observable} action$
 */
const updateFinalPlayerStatusesEpic = (action$, state) =>
  action$.pipe(
    ofType(updateFinalPlayerStatuses.type),
    concatMap(() => {
      const currentBetHistory = state.value.crash.currentBetHistory
      let out = []

      if (currentBetHistory.state === STATE_BASICS.Fulfilled) {
        const players = currentBetHistory.data
        out = players.map((p) => {
          let status = p._status
          if (status.state !== PLAYER_STATUSES.CashedOut) {
            status = staterPlayerStatuses.bang()
          }
          return { ...p, _status: status }
        })
      }

      return of(getCurrentBetHistoryFulfilled(out))
    })
  )

const crashEpics = [
  updateFinalPlayerStatusesEpic,
  getBetHistoryEpic,
  getPlayerHistoryEpic,
]

export default crashEpics

export const createGameEvent = (eventName) => fromEvent(socket, eventName)

/**
 * Creates an observable that emits a countdown of the specified duration.
 *
 * @param {number} [time=5000] The duration of the countdown in milliseconds.
 * @returns {import('rxjs').Observable<string>} An observable that emits the countdown as a string.
 */
export const countDown = (time = 5000) => {
  const ms = time / 1000
  return interval(100).pipe(
    map((i) => ms - i * 0.1),
    takeWhile((x) => x > 0),
    map((x) => x.toFixed(1))
  )
}

/**
 *
 *
 * @param {import('rxjs').Observable} observable1$ The type of the fulfilled action to take.
 * @param {import('rxjs').Observable} observable2$ The observable that emits all actions.
 * @returns {import('rxjs').OperatorFunction} The operator function.
 */
export const runInParallelAndWaitFor2nd =
  (observable1$, observable2$) => (source) =>
    new Observable((observer) => {
      const subscription1 = observable1$.subscribe()
      const sourceSubscription = source
        .pipe(
          switchMap((data) => {
            return observable2$.pipe(first())
          })
        )
        .subscribe({
          next(value) {
            observer.next(value)
          },
          error(err) {
            observer.error(err)
          },
          complete() {
            subscription1.unsubscribe()
            observer.complete()
          },
        })
      return () => {
        sourceSubscription.unsubscribe()
        subscription1.unsubscribe()
      }
    })
