import { Button } from '@/@components/ui/button'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const ButtonIcon = forwardRef(({ Icon, ...props }, ref) => {
  return (
    <Button variant="outline" size="icon" ref={ref} {...props}>
      <Icon className="h-4 w-4" />
    </Button>
  )
})

ButtonIcon.propTypes = {
  Icon: PropTypes.elementType.isRequired,
}

ButtonIcon.displayName = 'ButtonIcon'

export default ButtonIcon
