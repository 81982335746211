import { getURL } from '@/utilities'
import { getToken } from '@/utilities/cookie'
import { createContext } from 'react'
import { io } from 'socket.io-client'

export const socket = io(getURL('/game/crash'), {
  autoConnect: true,
  transports: ['websocket'],
  auth: (cb) => {
    const token = getToken()
    // eslint-disable-next-line n/no-callback-literal
    cb({
      token,
    })
  },
})

export const SocketContext = createContext(null)

socket.on('disconnect', () => {
  console.log('inspect.disconnected')
})

// to update auth token
export const updateSocketAuth = () => {
  socket.disconnect()
  socket.connect()
}
