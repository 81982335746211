import dayjs from 'dayjs'

/**
 * Returns a cloned Day.js object with a specified amount of time added.
 * ```
 * dayjs().add(7, 'day')// => Dayjs
 * ```
 * Units are case insensitive, and support plural and short forms.
 *
 * Docs: https://day.js.org/docs/en/manipulate/add
 * @param {number} value
 * @param {dayjs.ManipulateType} unit
 * @returns {dayjs.Dayjs} date
 */
export const getFutureDate = (value = 8, unit = 'h') =>
  dayjs().add(value, unit).toDate()
