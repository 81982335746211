import { Chart, LineController } from 'chart.js'

class CustomLineController extends LineController {}

CustomLineController.id = 'custom-line'
CustomLineController.defaults = LineController.defaults

Chart.register(CustomLineController)

export default CustomLineController
