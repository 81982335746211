import appEpics from '@/app/services/app'
import authEpics from '@/app/services/auth'
import notificationsEpics from '@/app/services/notification'
import crashEpics from '@/features/game-crash/actions'
import playerEpics from '@/features/player/actions'
import { combineEpics } from 'redux-observable'

export const rootEpic = combineEpics(
  ...appEpics,
  ...authEpics,
  ...notificationsEpics,
  ...crashEpics,
  ...playerEpics
)
